import type { GatsbyBrowser } from "gatsby"

import "./src/assets/app.scss"

const GRANTEES_PATH = `/grantees`
export const shouldUpdateScroll: GatsbyBrowser[`shouldUpdateScroll`] = ({
  prevRouterProps,
  routerProps,
}) => {
  // We don't want to scroll to the top of the page if they are just searching for things on
  // the grantees page because it'll force them to constantly scroll down to see the search bar.
  return !(
    prevRouterProps?.location.pathname.includes(GRANTEES_PATH) &&
    routerProps.location.pathname.includes(GRANTEES_PATH)
  )
}
